<template>
	<div>
		<v-btn color="success" rounded depressed @click.stop="onBeforeDialogOpen()">
			<v-icon left>$vuetify.icons.values.plus</v-icon>
			{{ $t("app.import_references") }}
		</v-btn>

		<v-dialog v-model="dialog" scrollable width="600px" persistent>
			<!-- Dialog Activator -->

			<AppDialog
				:heading="{
					label: 'app.import_references',
				}"
				:action="{
					label: 'app.import',
					event: 'import-reference',
				}"
				@close="onCloseEvent()"
				@import-reference="onImportEvent()"
			>
				<template v-slot:dialog-content>
					<div v-if="references && references.length">
						<v-checkbox
							v-for="item in references"
							:key="item.id"
							color="greenAccent2"
							v-model="selected_ids"
							:value="item"
							multiple
						>
							<template v-slot:label>
								<v-card flat>
									<v-card-title class="subtitle-1 py-0">
										{{ item.title }}
									</v-card-title>
									<v-card-text class="pt-0">{{ item.description }}</v-card-text>
								</v-card>
							</template>
						</v-checkbox>
					</div>
				</template>
			</AppDialog>
		</v-dialog>
	</div>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";
import { mindMapHelper } from "@/utils/helpers/mindmap.js";

export default {
	name: "ImportMindmapReferencesDialog",

	data() {
		return {
			dialog: false,
			references: null,
			selected_ids: [],
		};
	},

	components: {
		AppDialog,
	},

	methods: {
		async onBeforeDialogOpen() {
			await this.parseReferences();

			this.dialog = true;
		},

		async parseReferences() {
			try {
				this.$loader.start();

				this.references = await mindMapHelper.getStandardMapReferences();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onCloseEvent() {
			this.selected_ids = [];

			this.dialog = false;
		},

		onImportEvent() {
			this.$emit("add-reference-source", this.selected_ids);

			this.onCloseEvent();
		},
	},
};
</script>

<style></style>
