<template>
	<div>
		<SourceList></SourceList>
	</div>
</template>

<script>
import SourceList from "@/components/research/modules/gatherSources/source/List.vue";

export default {
	name: "SourcesWork",

	components: {
		SourceList,
	},
};
</script>

<style></style>
