<template>
	<v-dialog :value="dialog" width="900" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label: 'app.manage_tags',
			}"
			:action="
				current_tab == '1'
					? {
							label: 'app.save',
							event: 'close',
					  }
					: {
							label: 'app.save_new_tag',
							event: 'validate',
					  }
			"
			@close="$emit('close')"
			@validate="validate()"
		>
			<template v-slot:dialog-content>
				<v-card height="380" flat>
					<v-tabs
						v-model="current_tab"
						active-class="black--text font-weight-bold"
						slider-color="error"
						grow
					>
						<v-tab href="#1" class="title">
							{{ $t("app.select_tags") }}
						</v-tab>
						<v-tab href="#2" class="title">
							{{ $t("app.create_new_tag") }}
							<v-icon :left="$vuetify.rtl" :right="!$vuetify.rtl">{{
								$vuetify.icons.values.plus
							}}</v-icon>
						</v-tab>
					</v-tabs>

					<v-tabs-items v-model="current_tab" class="pt-5">
						<!-- select tags -->
						<v-tab-item value="1">
							<div>
								<v-chip-group column>
									<v-chip
										v-for="(tag, index) in tags"
										:key="tag.id"
										close
										:color="tag.color"
										label
										:dark="isColorDark(tag.color)"
										@click="$emit('update-source', tag)"
										@click:close="$emit('delete-tag', tag, index)"
									>
										<v-icon
											small
											left
											v-if="isArrayHavingItem(selected_tags, tag.id)"
											>$vuetify.icons.values.check_circle</v-icon
										>
										{{ tag.name }}
									</v-chip>
								</v-chip-group>
							</div>
						</v-tab-item>
						<!-- create new tag -->
						<v-tab-item value="2">
							<v-row>
								<v-col>
									<v-form ref="form">
										<div class="mb-2 subtitle-1 font-weight-bold info--text">
											{{ $t("app.tag_name") }}
										</div>
										<v-text-field
											v-model="form.name"
											outlined
											color="greenAccent2"
											background-color="white"
											:rules="[rules.required, rules.max(50)]"
										></v-text-field>

										<div class="mb-2 subtitle-1 font-weight-bold info--text">
											{{ $t("app.bg_color") }}
										</div>
										<v-color-picker
											v-model="form.color"
											hide-canvas
											hide-inputs
											width="100%"
											height="100%"
											hide-sliders
											hide-mode-switch
											show-swatches
											:rules="[rules.required]"
										></v-color-picker>
									</v-form>
								</v-col>
								<v-col sm="5">
									<div align="center">
										<div class="subtitle-1 font-weight-bold info--text">
											{{ $t("app.preview") }}
										</div>

										<div>
											{{ $t("app.create_tag_and_preview") }}
										</div>

										<v-chip
											v-if="form.name"
											:color="form.color"
											label
											class="mt-15 pa-5 subtitle-1"
											:dark="isColorDark(form.color)"
											>{{ form.name }}</v-chip
										>
									</div>
								</v-col>
							</v-row>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
const DEFAULT_COLOR = "#F44336FF";

import AppDialog from "@/components/ui/AppDialog.vue";
import { isColorDark, isArrayHavingItem } from "@/utils/helpers";
import rules from "@/utils/validation";

export default {
	name: "TagDialog",

	props: {
		dialog: {
			required: true,
		},
		tags: {
			required: true,
			type: Array,
		},
		selected_tags: {
			required: true,
		},
	},

	data() {
		return {
			rules,
			isColorDark,
			isArrayHavingItem,
			loading: false,
			current_tab: "1",
			form: {
				name: null,
				color: DEFAULT_COLOR,
			},
		};
	},

	components: {
		AppDialog,
	},

	methods: {
		validate() {
			try {
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				this.$emit("create-tag", this.form, (reset = true) => {
					this.loading = false;
					if (reset) {
						this.form.color = DEFAULT_COLOR;
						this.current_tab = "1";
						this.$refs.form.reset();
					}
				});
			} catch (error) {
				this.loading = false;
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
>>> .v-color-picker__color {
	width: 22px !important;
	height: 22px !important;
	max-height: none;
	border-radius: 50% !important;
}
</style>
