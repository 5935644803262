<template>
	<div>
		<!-- Display Related Types of Souce -->
		<v-expand-transition v-if="showRelatedTypes">
			<v-chip-group v-if="source.related_types">
				<v-chip
					:color="tag.color"
					label
					v-for="(tag, index) in source.related_types"
					:key="tag.id"
					:dark="isColorDark(tag.color)"
				>
					{{ tag.name }}
					<v-icon
						v-if="!isWorkRoute"
						@click="removeFromRelatedTypes(tag.id, index)"
						small
						right
						>$vuetify.icons.values.cancel</v-icon
					>
				</v-chip>
			</v-chip-group>
		</v-expand-transition>

		<!-- Tag icon, when click on it, tag management dialog will be open -->
		<v-tooltip v-if="showAddTypes" bottom color="wr_indigo_2">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					icon
					color="wr_blue_1"
					@click="tag_dialog = true"
				>
					<img :src="require('@/assets/images/supermarket.png')" width="26" />
				</v-btn>
			</template>
			<span>{{ $t("app.manage_tags") }}</span>
		</v-tooltip>

		<TagDialog
			v-if="tag_dialog"
			:dialog="tag_dialog"
			:tags="list"
			:selected_tags="source.new_related_types"
			@create-tag="create"
			@delete-tag="deleteTag"
			@close="tag_dialog = false"
			@update-source="updateSource"
		></TagDialog>
	</div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import TagDialog from "@/components/research/modules/gatherSources/source/TagDialog.vue";
import { isColorDark } from "@/utils/helpers";

export default {
	name: "SourceTag",

	props: {
		sourceId: {
			required: true,
			type: Number,
		},

		showAddTypes: {
			required: true,
			type: Boolean,
		},

		showRelatedTypes: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			isColorDark,
			dialog: false,
			tag_dialog: false,
		};
	},

	components: {
		TagDialog,
	},

	computed: {
		...mapState({
			list: (state) => state.tag.list,
		}),

		...mapGetters({
			getSource: "source/getSource",
		}),

		source() {
			return this.getSource(this.sourceId);
		},
		isWorkRoute() {
			return this.$route.name === "SourcesWork";
		},
	},

	methods: {
		removeFromRelatedTypes(tagId, index) {
			this.source.related_types.splice(index, 1);
			var tagIndex = this.source.new_related_types.indexOf(tagId);
			this.source.new_related_types.splice(tagIndex, 1);
			this.$store.dispatch("source/update", this.source);
		},

		async updateSource(tag) {
			try {
				if (this.source.new_related_types.indexOf(tag.id) !== -1) {
					return;
				}

				this.$loader.start();

				this.source.new_related_types.push(tag.id);
				this.source.related_types.push(tag);
				/**
				 * del attachment key as put api expect attachment
				 * to always be a file
				 */
				var source = Object.assign({}, this.source);
				delete source["attachment"];

				this.$store.dispatch("source/update", source);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async deleteTag(tag, index) {
			try {
				this.$loader.start();

				await this.$store.dispatch("tag/delete", tag.id);

				this.$store.dispatch("tag/list");

				this.removeFromRelatedTypes(tag.id, index);
			} catch (error) {
				this.$announce.error(error);
				this.dialog = false;
			} finally {
				this.$loader.stop();
			}
		},

		async create(payload, cb) {
			try {
				// sanitize input before save
				payload.name = this.$xss.sanitize(payload.name);

				await this.$store.dispatch("tag/create", payload);

				this.$store.dispatch("tag/list");

				cb();
			} catch (error) {
				cb(false);
				this.$announce.error(error);
			}
		},
	},
};
</script>
