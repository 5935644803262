<template>
	<div>
		<!-- Display Related Keywords of Souce -->
		<v-expand-transition v-if="showRelatedKeywords">
			<v-card-text class="pt-0">
				<v-chip-group
					v-if="source.related_keywords && source.related_keywords.length"
				>
					<v-chip
						color="greyLighten2"
						v-for="(keyword, index) in source.related_keywords"
						:key="keyword.id"
					>
						{{ keyword.name }}
						<v-icon
							v-if="!isWorkRoute"
							small
							right
							@click="removeFromRelatedKeywords(keyword.id, index)"
							>$vuetify.icons.values.cancel</v-icon
						>
					</v-chip>
				</v-chip-group>
			</v-card-text>
		</v-expand-transition>

		<!-- Keyword icon, when click on it, keywords related dialog will be open -->
		<v-tooltip v-if="showAddRelatedKeywords" bottom color="wr_indigo_2">
			<template v-slot:activator="{ on, attrs }">
				<v-btn icon v-bind="attrs" v-on="on" @click="dialog = true">
					<img :src="require('@/assets/images/www.png')" width="26" />
				</v-btn>
			</template>
			<span>{{ $t("app.related_keywords") }}</span>
		</v-tooltip>

		<KeywordsRelatedDialog
			v-model="dialog"
			:dialog="dialog"
			:related_keywords="source.related_keywords"
			@close="dialog = false"
			@update-source="updateSource"
		></KeywordsRelatedDialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import KeywordsRelatedDialog from "@/components/research/modules/gatherSources/source/KeywordsRelatedDialog.vue";

export default {
	name: "SourceKeywordsRelated",

	props: {
		sourceId: {
			type: Number,
		},

		showRelatedKeywords: {
			required: true,
			type: Boolean,
		},

		showAddRelatedKeywords: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			dialog: false,
		};
	},

	components: {
		KeywordsRelatedDialog,
	},

	computed: {
		...mapGetters({
			getSource: "source/getSource",
		}),

		source() {
			return this.getSource(this.sourceId);
		},

		isWorkRoute() {
			return this.$route.name === "SourcesWork";
		},
	},

	methods: {
		removeFromRelatedKeywords(keywordId, index) {
			this.source.related_keywords.splice(index, 1);
			var keywordIndex = this.source.new_related_keywords.indexOf(keywordId);
			this.$store.dispatch("source/update", this.source);
		},

		async updateSource(keyword) {
			try {
				if (this.source.new_related_keywords.indexOf(keyword.id) !== -1) {
					return;
				}

				this.source.new_related_keywords.push(keyword.id);

				this.source.related_keywords.push(keyword);

				var source = Object.assign({}, this.source);

				delete source["attachment"];

				await this.$store.dispatch("source/update", source);
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
