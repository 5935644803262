<template>
	<v-card flat class="wr_grey_1 mt-5" height="100%">
		<v-card-text class="full-height-wr">
			<v-row
				no-gutters
				v-if="!isWorkRoute && source_list && source_list.length"
			>
				<v-col sm="1">
					<!-- Create Source -->
					<v-tooltip
						:right="!$vuetify.rtl"
						:left="$vuetify.rtl"
						color="wr_indigo_2"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								small
								fab
								dark
								v-bind="attrs"
								v-on="on"
								color="success"
								@click="createSource"
							>
								<v-icon dark large>$vuetify.icons.values.plus</v-icon>
							</v-btn>
						</template>
						<span>{{ $t("app.create_source") }}</span>
					</v-tooltip>
				</v-col>
				<v-col>
					<v-card-title class="headline font-weight-black pt-1">{{
						$t("app.source_list")
					}}</v-card-title>
				</v-col>
				<v-col align="end">
					<ImportMindmapReferencesDialog
						@add-reference-source="createSource"
					></ImportMindmapReferencesDialog>
				</v-col>
			</v-row>

			<!-- Source -->
			<div v-if="source_list && source_list.length">
				<Source
					v-for="(source, index) in source_list"
					:key="index"
					:source_num="index + 1"
					:source_id="source.id"
				/>
			</div>
			<NoData
				v-else
				heading="app.no_sources_yet"
				:show_action="!isWorkRoute"
				@init="createSource()"
			></NoData>
		</v-card-text>
	</v-card>
</template>

<script>
import { sourceMixin } from "@/mixins/sourceMixin";
import NoData from "@/components/shared/NoData.vue";
import Source from "@/components/research/modules/gatherSources/source/Index.vue";
import ImportMindmapReferencesDialog from "@/components/research/modules/defineQuestion/stages/gatherInformation/ImportMindmapReferencesDialog.vue";

export default {
	name: "SourceList",

	mixins: [sourceMixin],

	components: {
		Source,
		NoData,
		ImportMindmapReferencesDialog,
	},

	computed: {
		isWorkRoute() {
			return this.$route.name === "SourcesWork";
		},
	},

	created() {
		this.getTagList();
	},

	methods: {
		getTagList() {
			try {
				this.$store.dispatch("tag/list");
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
