<template>
	<!-- Create a keyword Dialog -->
	<v-dialog persistent v-model="dialog" width="700">
		<!-- Dialog Activator -->
		<template v-slot:activator="{ on: dialog, attrs }">
			<v-tooltip
				:right="!$vuetify.rtl"
				:left="$vuetify.rtl"
				color="wr_indigo_2"
			>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
						<v-icon color="amberDarken1">$vuetify.icons.values.edit</v-icon>
					</v-btn>
				</template>
				<span>{{ $t("app.edit") }}</span>
			</v-tooltip>
		</template>

		<!-- Dialog Content -->
		<AppDialog
			v-model="loading"
			:heading="{
				label: 'app.edit_source',
			}"
			:action="{
				label: 'app.save',
				event: 'save',
			}"
			@close="dialog = false"
			@save="save()"
		>
			<template v-slot:dialog-content>
				<v-form v-if="dialog && source" ref="form">
					<v-text-field
						:label="$t('app.placeholder.add_title')"
						v-model="item.title"
						outlined
						color="greenAccent2"
						background-color="white"
						:rules="[rules.required, rules.max(250)]"
					>
						<span slot="append" class="me-2">
							<SpeechToText
								:text="item.title"
								@speechend="item.title = $event.text"
							></SpeechToText>
						</span>
						<TextToSpeech slot="append" :text="item.title"></TextToSpeech>
					</v-text-field>
					<v-textarea
						:label="$t('app.placeholder.add_desc')"
						v-model="item.description"
						outlined
						color="greenAccent2"
						background-color="white"
						:rules="[rules.required]"
					>
						<span slot="append" class="me-2">
							<SpeechToText
								:text="item.description"
								@speechend="item.description = $event.text"
							></SpeechToText>
						</span>
						<TextToSpeech slot="append" :text="item.description"></TextToSpeech>
					</v-textarea>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";
import SpeechToText from "@/components/plugins/SpeechToText";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import { mapGetters } from "vuex";

export default {
	name: "MetaDialog",

	props: {
		sourceId: {
			required: true,
			type: Number,
		},
	},

	data() {
		return {
			rules,
			dialog: false,
			loading: false,
			item: {
				title: null,
				description: null,
			},
		};
	},

	watch: {
		dialog(newVal) {
			if (newVal) {
				this.item.title = this.source.title;
				this.item.description = this.source.description;
			}
		},
	},

	computed: {
		...mapGetters({
			getSource: "source/getSource",
		}),

		source() {
			return this.getSource(this.sourceId);
		},
	},

	components: {
		AppDialog,
		SpeechToText,
		TextToSpeech,
	},

	methods: {
		async save() {
			try {
				if (!this.$refs.form.validate()) return;

				this.loading = true;

				/**
				 * del attachment key as put api expect attachment
				 * to always be a file
				 */

				delete this.source["attachment"];

				// Copy form data to respected places.
				this.source.title = this.$xss.sanitize(this.item.title);
				this.source.description = this.$xss.sanitize(this.item.description);

				await this.$store.dispatch("source/update", this.source);

				this.$refs.form.reset();

				this.dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
