import { mapState } from "vuex";
import moment from "moment";
import { mindMapHelper } from "@/utils/helpers/mindmap.js";

export const sourceMixin = {
	computed: {
		...mapState({
			source_list: (state) => state.source.list,
		}),
	},

	created() {
		this.getSourceList();
	},

	methods: {
		async createSource(payload = null) {
			try {
				this.$loader.start();

				let source_data = [];

				function pushToSourcesArr(obj = {}) {
					let obj_copy = Object.assign({}, obj);
					// default properties
					obj_copy.review_date = moment().format("YYYY-MM-DD");
					obj_copy.publication_date = moment().format("YYYY-MM-DD");
					// push to source information array
					source_data.push(obj_copy);
				}

				/**
				 * Payload will be available in form of an array
				 * if source is imported from the mind map refrences
				 */
				if (payload && Array.isArray(payload)) {
					payload.forEach((item) => {
						pushToSourcesArr({
							title: item.title,
							url: item.link,
							description: item.description,
						});
					});
				}
				// otherwise it will be an single object
				else {
					pushToSourcesArr();
				}

				await this.$store.dispatch("source/create", source_data);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getSourceList() {
			try {
				this.$loader.start();
				// Get all sources from the API
				await this.$store.dispatch("source/list");
				// If sources available then fine
				if (this.source_list && this.source_list.length) return;
				// Else, get the reference created in the mindmap
				let references = await mindMapHelper.getStandardMapReferences();
				// If references are not available, do nothing
				if (!references || !references.length) return;
				// Else, create sources fromt the references.
				await this.createSource(references);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
