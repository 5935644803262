<template>
	<v-row no-gutters v-if="source" class="mt-6">
		<v-col sm="1" class="display-2 wr_blue_1--text">
			<!-- Source number -->
			<div>{{ source_num }}</div>

			<div v-if="canAccessSelection">
				<!-- Selection checkbox -->
				<v-checkbox
					v-if="isSourceEvaluated && !isSourceUnDocumented(source)"
					on-icon="$vuetify.icons.values.check_outline"
					rounded
					color="wr_blue_1"
					hide-details
					class="mb-4"
					:input-value="source.selected"
					:disabled="isWorkRoute"
					:true-value="true"
					:false-value="false"
					@click="toggleSelected"
				></v-checkbox>

				<!-- Evaluation dialog -->
				<EvaluationDialog
					v-if="canAccessEvaluation"
					v-model="source"
					@save="updateSource"
					:showPendingEvaluation="true"
					:showEvaluatedScore="false"
					:showEvaluationForm="false"
				/>
			</div>
		</v-col>

		<v-col sm="11">
			<v-card :class="[source.selected ? 'border-wr' : '', 'elevation-4']">
				<v-card-text>
					<v-row no-gutters>
						<v-col sm="9">
							<v-row no-gutters>
								<v-col sm="5">
									<div>
										<u
											v-if="source.title"
											class="font-weight-bold black--text"
											>{{ source.title }}</u
										>
										<i v-else>{{ $t("app.click_edit__to_add_title") }}</i>
										<MetaDialog v-if="!isWorkRoute" :sourceId="source.id" />
									</div>
									<div>
										<read-more
											v-if="source.description"
											:more-str="$t('app.read_more')"
											:text="source.description"
											:less-str="$t('app.read_less')"
											:max-chars="300"
											class="black--text"
										></read-more>
										<i v-else>{{ $t("app.click_edit__to_add_description") }}</i>
									</div>
								</v-col>

								<!-- Apply and create tags -->
								<v-col sm="6">
									<Tag
										:sourceId="source.id"
										:showAddTypes="false"
										:showRelatedTypes="true"
									/>
								</v-col>

								<!-- Delete source -->
								<v-col v-if="canDeleteSource" sm="1" align="end">
									<v-tooltip bottom color="wr_indigo_2">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												icon
												color="error"
												@click="dialog = true"
											>
												<v-icon>$vuetify.icons.values.delete</v-icon>
											</v-btn>
										</template>
										<span>{{ $t("app.delete_source") }}</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-col>

						<v-col sm="1" align="center">
							<v-divider class="wr-dash-divider" vertical></v-divider>
						</v-col>

						<!-- Addons -->
						<v-col sm="2" align="center">
							<SourceAddons
								:sourceId="source.id"
								:showAddons="true"
								:showAdd="false"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<!-- Show attach keywords -->
				<KeywordsRelated
					:sourceId="source.id"
					:showRelatedKeywords="true"
					:showAddRelatedKeywords="false"
				/>
				<v-divider></v-divider>

				<!-- Show attach keywords -->
				<SourceNotes :sourceId="source.id"></SourceNotes>
				<v-divider></v-divider>

				<!-- Documentation section -->
				<SourceDocumentation
					v-if="canAccessDocumentation"
					:sourceId="source.id"
				/>

				<!-- Evaluation section -->
				<EvaluationDialog
					v-if="canAccessEvaluation"
					v-model="source"
					@save="updateSource"
					:showEvaluatedScore="true"
					:showEvaluationForm="true"
				/>

				<!-- Actions on Source -->
				<v-card-actions class="source-actions-wr" v-if="!isWorkRoute">
					<SourceAddons
						:sourceId="source.id"
						:showAdd="true"
						:showAddons="false"
						@addLink="addLink"
					/>
					<v-spacer></v-spacer>
					<Tag
						:sourceId="source.id"
						:showAddTypes="true"
						:showRelatedTypes="false"
					/>
					<v-spacer></v-spacer>
					<KeywordsRelated
						:sourceId="source.id"
						:showRelatedKeywords="false"
						:showAddRelatedKeywords="true"
					/>
				</v-card-actions>
			</v-card>

			<!-- Confirmation box for delete operation -->
			<TheConfirmation
				v-model="dialog"
				:dialog="dialog"
				@cancel="dialog = false"
				@confirm="sourceDel()"
			></TheConfirmation>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import KeywordsRelated from "@/components/research/modules/gatherSources/source/KeywordsRelated.vue";
import Tag from "@/components/research/modules/gatherSources/source/Tag.vue";
import SourceAddons from "@/components/research/modules/gatherSources/source/Addons.vue";
import TheConfirmation from "@/components/layout/TheConfirmation";
import SourceDocumentation from "@/components/research/modules/gatherSources/source/Documentation.vue";
import EvaluationDialog from "@/components/research/modules/gatherSources/source/EvaluationDialog.vue";
import MetaDialog from "@/components/research/modules/gatherSources/source/MetaDialog.vue";
import SourceNotes from "@/components/research/modules/gatherSources/source/Notes.vue";
import { isArrayHavingItem, findFromArray } from "@/utils/helpers";
import { isSourceUnDocumented } from "@/utils/helpers/source.js";

export default {
	name: "Source",

	props: {
		source_id: {
			required: true,
			type: Number,
		},

		source_num: {
			required: true,
		},
	},

	data() {
		return {
			dialog: false,
			delSourceId: null,
			source: null,
		};
	},

	mounted() {
		this.setSource();
	},

	computed: {
		...mapState({
			sources: (state) => state.source.list,
		}),

		isSourceEvaluated: function() {
			var score =
				this.source.sr_reliability +
				this.source.sr_info_quality +
				this.source.sr_beneficiality;
			return score > 0;
		},

		currentRoute() {
			return this.$route.name;
		},

		isWorkRoute() {
			return this.currentRoute === "SourcesWork";
		},

		canAccessDocumentation() {
			return isArrayHavingItem(
				[
					"SourceDocumentation",
					"SourceEvaluation",
					"SourceSelection",
					"SourcesWork",
				],
				this.currentRoute,
			);
		},

		canAccessEvaluation() {
			return isArrayHavingItem(
				["SourceEvaluation", "SourceSelection", "SourcesWork"],
				this.currentRoute,
			);
		},

		canAccessSelection() {
			return isArrayHavingItem(
				["SourceSelection", "SourcesWork"],
				this.currentRoute,
			);
		},

		canDeleteSource() {
			return !this.canAccessEvaluation;
		},
	},

	components: {
		KeywordsRelated,
		SourceAddons,
		Tag,
		TheConfirmation,
		SourceDocumentation,
		EvaluationDialog,
		MetaDialog,
		SourceNotes,
	},

	methods: {
		isSourceUnDocumented,

		toggleSelected() {
			this.source.selected = !this.source.selected;
			this.updateSource();
		},

		addLink(payload) {
			this.source.url = payload.url;
			this.updateSource();
		},

		setSource() {
			this.source = findFromArray(this.sources, "id", this.source_id);
		},

		async updateSource() {
			try {
				/**
				 * del attachment key as put api expect attachment
				 * to always be a file
				 */
				var source = Object.assign({}, this.source);
				delete source["attachment"];

				this.$loader.start();
				await this.$store.dispatch("source/update", source);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async sourceDel() {
			try {
				this.$loader.start();
				await this.$store.dispatch("source/del", {
					id: this.source.id,
				});
				this.$store.dispatch("source/list");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
				this.dialog = false;
			}
		},
	},
};
</script>

<style scoped>
.source-actions-wr {
	width: 160px;
}

.border-wr {
	border: 2px solid var(--v-wr_blue_1-base) !important;
}
</style>
