var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"right":!_vm.$vuetify.rtl,"left":_vm.$vuetify.rtl,"color":"wr_indigo_2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"color":"amberDarken1"}},[_vm._v("$vuetify.icons.values.edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("app.edit")))])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AppDialog',{attrs:{"heading":{
			label: 'app.edit_source',
		},"action":{
			label: 'app.save',
			event: 'save',
		}},on:{"close":function($event){_vm.dialog = false},"save":function($event){return _vm.save()}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [(_vm.dialog && _vm.source)?_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":_vm.$t('app.placeholder.add_title'),"outlined":"","color":"greenAccent2","background-color":"white","rules":[_vm.rules.required, _vm.rules.max(250)]},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}},[_c('span',{staticClass:"me-2",attrs:{"slot":"append"},slot:"append"},[_c('SpeechToText',{attrs:{"text":_vm.item.title},on:{"speechend":function($event){_vm.item.title = $event.text}}})],1),_c('TextToSpeech',{attrs:{"slot":"append","text":_vm.item.title},slot:"append"})],1),_c('v-textarea',{attrs:{"label":_vm.$t('app.placeholder.add_desc'),"outlined":"","color":"greenAccent2","background-color":"white","rules":[_vm.rules.required]},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}},[_c('span',{staticClass:"me-2",attrs:{"slot":"append"},slot:"append"},[_c('SpeechToText',{attrs:{"text":_vm.item.description},on:{"speechend":function($event){_vm.item.description = $event.text}}})],1),_c('TextToSpeech',{attrs:{"slot":"append","text":_vm.item.description},slot:"append"})],1)],1):_vm._e()]},proxy:true}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }