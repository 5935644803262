<template>
	<v-dialog :value="dialog" max-width="800" persistent>
		<AppDialog
			v-model="loading"
			:heading="{
				label: 'app.upload_file_or_insert_link',
			}"
			:action="{
				label: 'app.save',
				event: 'validate',
				condition: selected_option === INSERT_LINK,
			}"
			@close="$emit('close')"
			@validate="validate()"
		>
			<template v-slot:dialog-content>
				<div class="greyDarken4--text subtitle-1">
					{{ $t("app.click_action_you_want") }}
				</div>

				<!------------------------------------
        AVAILABLE OPTIONS UI
      ------------------------------------>

				<v-row no-gutters>
					<v-col v-for="(option, index) in options" :key="index">
						<v-card
							outlined
							:dark="isOptionSelected(option.value)"
							:class="[isOptionSelected(option.value) ? 'info' : null, 'my-3']"
							@click="validateSelectedOption(option.value)"
						>
							<v-card-text
								:class="[
									'subtitle-1',
									isOptionSelected(option.value) ? 'white--text' : null,
								]"
							>
								<v-row no-gutters>
									<v-col sm="10"
										><v-icon>{{ $vuetify.icons.values[option.icon] }}</v-icon>
										{{ $t(option.name) }}</v-col
									>
									<v-col class="text-end">
										<v-icon>{{
											$vuetify.icons.values[
												isOptionSelected(option.value)
													? "check_circle"
													: "circle_outline"
											]
										}}</v-icon>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>

				<v-row no-gutters class="mt-3">
					<!------------------------------------
            IF NONE OF THE OPTIONS IS SELECTED
           ------------------------------------>
					<div v-if="!selected_option" class="greyDarken4--text">
						<!-- Instruction heading -->
						<div class="mt-2 title">
							{{ $t("app.instructions") }}
						</div>

						<!-- Instructions in detail -->
						<div class="mt-5">
							<ul class="the-header-instructions-list-wr">
								<li v-html="$t('app.upload_media_instructions')" />
								<li v-html="$t('app.insert_link_instructions')" />
								<li class="mt-3" v-html="$t('app.upload_insert_warning')" />
							</ul>
						</div>
					</div>

					<!------------------------------------
            IF UPLOAD MEDIA IS SELECTED
           ------------------------------------>
					<v-card
						v-if="selected_option === UPLOAD_MEDIA"
						width="100%"
						flat
						class="mt-8"
					>
						<Uploader
							:upload_url="uploadUrl"
							:document_url="documentUrl"
							:append_data="appendData"
							@uploaded-document-removed="$emit('remove-attachment')"
							@upload-success="$emit('on-upload-success', $event)"
						>
							<template v-slot:dropzone-display-content>
								<v-card flat height="190" class="greyLighten4 border-wr">
									<v-container fill-height fluid>
										<v-row justify="center">
											<v-col align="center">
												<v-card-text>
													<v-img
														:src="require('@/assets/images/upload.png')"
														width="70"
													>
													</v-img>
												</v-card-text>
												<v-card-text
													v-html="$t('app.upload_or_drag_file')"
													class="pt-0 subtitle-1 greyDarken4--text"
												>
												</v-card-text>
											</v-col>
										</v-row>
									</v-container>
								</v-card>
							</template>
						</Uploader>
					</v-card>

					<!------------------------------------
            IF INSERT LINK IS SELECTED
           ------------------------------------>
					<div
						v-if="selected_option === INSERT_LINK"
						class="greyDarken4--text mt-8"
					>
						<v-form ref="form">
							<v-text-field
								v-model="url"
								outlined
								:rules="[rules.required, rules.isValidURL(url)]"
								:label="$t('app.any_web_address')"
								color="greenAccent2"
								background-color="white"
								:prepend-inner-icon="$vuetify.icons.values.link"
								:append-icon="$vuetify.icons.values.delete"
								@click:append="deleteLink()"
							>
							</v-text-field>
						</v-form>

						<a :href="href" target="_blank" class="font-weight-bold">
							{{ $t("app.test_link") }}
						</a>

						<div
							class="mt-3"
							v-html="$t('app.insert_link_instructions_desc')"
						></div>
					</div>
				</v-row>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import Uploader from "@/components/plugins/Uploader";
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";

const UPLOAD_MEDIA = 1;
const INSERT_LINK = 2;

export default {
	name: "AddonsDialog",

	props: {
		dialog: {
			required: true,
		},
		source: {
			required: true,
		},
	},

	data() {
		return {
			rules,
			UPLOAD_MEDIA,
			INSERT_LINK,
			loading: false,
			base_api_url: this.$environments.base_api_url,
			selected_option: null,
			options: [
				{
					name: "app.upload_media",
					value: UPLOAD_MEDIA,
					icon: "upload",
				},
				{
					name: "app.insert_link",
					value: INSERT_LINK,
					icon: "link",
				},
			],
			url: this.source.url,
		};
	},

	components: {
		Uploader,
		AppDialog,
	},

	computed: {
		uploadUrl() {
			return `${this.base_api_url}/rsc/user_source/${this.source.id}/`;
		},

		documentUrl() {
			return this.source.attachment
				? `${this.base_api_url}${this.source.attachment}`
				: null;
		},

		appendData() {
			return {
				review_date: this.source.review_date,
				publication_date: this.source.publication_date,
				selected: this.source.selected,
			};
		},

		href() {
			if (!this.url) return;

			return this.url.startsWith("http://") || this.url.startsWith("https://")
				? this.url
				: `//${this.url}`;
		},
	},

	mounted() {
		this.setMediaOrLink();
	},

	methods: {
		isOptionSelected(value) {
			return this.selected_option === value;
		},

		setMediaOrLink() {
			try {
				if (this.source.attachment) {
					this.selected_option = UPLOAD_MEDIA;
				}
				if (this.source.url) {
					this.selected_option = INSERT_LINK;
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		validate() {
			try {
				if (!this.$refs.form.validate()) return;

				this.loading = true;
				this.$emit("attach-link", this.url, () => {
					this.loading = false;
				});
			} catch (error) {
				this.loading = false;
				this.$announce.error(error);
			}
		},

		validateSelectedOption(value) {
			try {
				if (
					(value === INSERT_LINK && this.source.attachment) ||
					(value === UPLOAD_MEDIA && this.source.url)
				) {
					throw new Error("app.ntfy.err.only_one_addon_allowed", {
						cause: "werCustom",
					});
				}

				this.selected_option = value;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		deleteLink() {
			try {
				this.$refs.form.reset();
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.border-wr {
	border: 3px dashed lightgrey !important;
}
</style>
