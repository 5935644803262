<template>
	<div>
		<!-- Pending evaluation -->
		<v-menu
			v-if="
				showPendingEvaluation &&
					(!evaluationScore || isSourceUnDocumented(value))
			"
			open-on-hover
			offset-x
		>
			<template v-slot:activator="{ on, attrs }">
				<v-icon v-bind="attrs" v-on="on" color="error"
					>$vuetify.icons.values.info</v-icon
				>
			</template>
			<v-card outlined class="card-wr" width="300">
				<v-card-text>
					<v-row no-gutters>
						<v-col sm="10">
							{{
								!evaluationScore
									? $t("app.msg.source_evaluation_not_completed")
									: $t("app.msg.source_documentation_not_completed")
							}}
						</v-col>
						<v-col class="text-end">
							<v-icon class="cursor-wr"
								>$vuetify.icons.values.cancel_basic</v-icon
							>
						</v-col>
					</v-row>

					<AppButton
						v-if="!evaluationScore && !isWorkRoute"
						label="app.complete_evaluation"
						color="greenAccent2"
						:prop_class="['mt-5']"
						:block="true"
						@click="dialog = true"
					></AppButton>
				</v-card-text>
			</v-card>
		</v-menu>

		<!-- Evaluate Score -->
		<div v-if="showEvaluatedScore && evaluationScore">
			<v-expansion-panels flat v-model="panel" multiple>
				<v-expansion-panel>
					<v-expansion-panel-header class="px-4">
						<template v-slot:actions>
							<v-icon color="wr_blue_1" size="24" class="expansion-left-icon-wr"
								>$vuetify.icons.values.arrow_down</v-icon
							>
						</template>
						<div class="expansion-left-icon-header-wr mx-2">
							<span class="font-weight-bold subtitle-1 ">{{
								$t("app.evaluation")
							}}</span>
						</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-card outlined class="greyLighten4">
							<v-card-text>
								<v-row no-gutters>
									<v-col align="center" class="pt-3 subtitle-1 black--text">
										{{
											$t("app.source_evaluation.final_score.question_1.title")
										}}
										<v-btn depressed icon class="wr_indigo_2" dark>
											<span class="headline">{{ value.sr_reliability }}</span>
										</v-btn>
									</v-col>
									<v-col align="center" class="pt-3 subtitle-1 black--text">
										{{
											$t("app.source_evaluation.final_score.question_2.title")
										}}
										<v-btn depressed icon class="wr_indigo_2" dark>
											<span class="headline">{{ value.sr_info_quality }}</span>
										</v-btn>
									</v-col>
									<v-col align="center" class="pt-3 subtitle-1 black--text">
										{{
											$t("app.source_evaluation.final_score.question_3.title")
										}}
										<v-btn depressed icon class="wr_indigo_2" dark>
											<span class="headline">{{ value.sr_beneficiality }}</span>
										</v-btn>
									</v-col>
									<v-col class="text-end">
										<v-btn
											depressed
											large
											fab
											color="greenAccent2"
											class="font-weight-bold overline"
										>
											{{ $t("app.score") }}
											<br />
											{{ evaluationScore }}
										</v-btn>
									</v-col>
								</v-row>
								<AppButton
									label="app.edit"
									color="success"
									icon="edit"
									:prop_class="['mt-3']"
									@click="dialog = true"
								></AppButton>
							</v-card-text>
						</v-card>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>

		<!-- Evaluate Source Dialog -->
		<v-dialog persistent v-model="dialog" width="1000">
			<!-- Dialog Activator -->
			<template v-slot:activator="{ on, attrs }">
				<div
					v-if="showEvaluationForm && !evaluationScore"
					v-bind="attrs"
					v-on="on"
					class="ps-8 py-4 font-weight-bold subtitle-1"
				>
					{{ $t("app.evaluation") }}
				</div>
			</template>
			<AppDialog
				:heading="{
					label: 'app.evaluation',
				}"
				:action="
					current_step == evaluation_questions.length
						? {
								label: 'app.save',
								event: 'save',
						  }
						: null
				"
				@close="dialog = false"
				@save="save()"
			>
				<template v-slot:dialog-content>
					<!-- Dialog Content -->
					<v-card-title v-if="value.title" class="font-weight-bold">
						<u>{{ value.title }}</u>
					</v-card-title>
					<v-card-text v-if="value.description">
						<read-more
							:more-str="$t('app.read_more')"
							:text="value.description"
							:less-str="$t('app.read_less')"
							:max-chars="300"
							class="black--text"
						></read-more>
					</v-card-text>

					<v-stepper v-model="current_step" vertical flat>
						<template v-for="(questionnaire, index) in evaluation_questions">
							<v-stepper-step
								:key="'s' + index"
								:complete="
									_isCompleted(questionnaire.questions) &&
										current_step > index + 1
								"
								:editable="index + 1 < current_step"
								edit-icon="$vuetify.icons.values.check"
								:step="index + 1"
								color="wr_indigo_2"
								class="subtitle-1 black--text font-weight-bold"
							>
								{{ $t(questionnaire.name) }}
								<small>{{ $t(questionnaire.subtitle) }}</small>
							</v-stepper-step>

							<v-stepper-content :step="index + 1" :key="'c' + index">
								<v-form :ref="`form-${index + 1}`" :key="'s' + index">
									<div
										v-for="(question, queIndex) in questionnaire.questions"
										:key="'que-' + queIndex"
									>
										<div class="font-weight-bold">
											{{ $t(question.title) }}
										</div>
										<div v-if="question.subtitle" class="mt-1">
											{{ $t(question.subtitle) }}
										</div>

										<v-textarea
											v-if="question.type == 'text'"
											rows="2"
											outlined
											dense
											class="mt-2"
											color="greenAccent2"
											background-color="white"
											:disabled="isWorkRoute"
											:value="value[question.key]"
											:rules="[rules.required]"
											@input="_updateInput($event, question.key)"
										></v-textarea>
										<p v-if="question.type == 'number'">
											<v-row no-gutters>
												<v-col>
													<v-slider
														:min="0"
														:max="10"
														step="1"
														color="amberDarken1"
														track-color="amberDarken1"
														thumb-label="always"
														thumb-color="wr_indigo_2"
														:value="value[question.key]"
														:readonly="isWorkRoute"
														@input="_updateInput($event, question.key)"
														:rules="[rules.min(1)]"
													>
														<template v-slot:prepend>
															<small>{{ $t("app.not_at_all") }}</small>
														</template>
														<template v-slot:append>
															<small>{{ $t("app.very_much") }}</small>
														</template>
													</v-slider>
												</v-col>
											</v-row>
										</p>
									</div>
								</v-form>
								<AppButton
									v-if="current_step != evaluation_questions.length"
									label="app.continue"
									color="wr_indigo_2"
									:dark="true"
									@click="validateQuestionnaire()"
								></AppButton>

								<AppButton
									v-if="current_step != 1"
									label="app.back"
									color="greyLighten2"
									:prop_class="['ms-3']"
									@click="current_step = current_step - 1"
								></AppButton>
							</v-stepper-content>
						</template>
					</v-stepper>
				</template>
			</AppDialog>
		</v-dialog>
		<v-divider v-if="!showPendingEvaluation"></v-divider>
	</div>
</template>

<script>
import rules from "@/utils/validation";
import { EVALUATION_QUESTIONS } from "@/utils/helpers/source";
import AppDialog from "@/components/ui/AppDialog.vue";
import { isSourceUnDocumented } from "@/utils/helpers/source.js";

export default {
	name: "SourceEvaluation",

	props: [
		"value",
		"showEvaluatedScore",
		"showEvaluationForm",
		"showPendingEvaluation",
	],

	data() {
		return {
			dialog: false,
			panel: [0],
			rules,
			current_step: 1,
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		evaluation_questions() {
			return EVALUATION_QUESTIONS;
		},

		evaluationScore() {
			return (
				this.value.sr_reliability +
				this.value.sr_info_quality +
				this.value.sr_beneficiality
			);
		},

		isWorkRoute() {
			return this.$route.name === "SourcesWork";
		},
	},

	methods: {
		isSourceUnDocumented,

		_isCompleted(questions) {
			var output = questions.find(
				(question) =>
					this.value[question.key] == null || this.value[question.key] == 0,
			);
			return output == undefined;
		},

		_updateInput($event, key) {
			var obj = {};
			obj[key] = $event;
			this.$emit("input", Object.assign({}, this.value, obj));
		},

		validateQuestionnaire(payload) {
			// each questionnaire has individual form.
			if (!this.$refs[`form-${this.current_step}`][0].validate()) {
				return;
			}
			// update current step
			this.current_step = this.current_step + 1;
		},

		save() {
			/**
			 * Save will only visible on last questionnaire, so validate
			 * only last questionnaire form.
			 */
			if (!this.$refs[`form-${this.current_step}`][0].validate()) {
				return false;
			}
			// emit save event to parent component
			this.$emit("save");
			// close the dialog
			this.dialog = false;
		},
	},
};
</script>

<style scoped>
/* >>> .v-timeline-item__inner-dot .v-icon {
	color: var(--v-amberDarken1-base);
} */
.card-wr {
	border: 1px solid var(--v-error-base) !important;
}
</style>
