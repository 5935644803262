<template>
	<div>
		<!-- Display attachment or url -->
		<div v-if="showAddons && source && (source.attachment || source.url)">
			<v-icon
				v-if="!isWorkRoute"
				color="error"
				@click="removeAddon(source.attachment ? 'attachment' : 'url')"
				>$vuetify.icons.values.close</v-icon
			>
			<div class="mt-1">
				<a
					:href="source.attachment ? getAttachmentUrl() : source.url"
					target="_blank"
					class="text-decoration-none"
				>
					<v-icon color="info" size="22">
						{{
							$vuetify.icons.values[
								source.attachment ? "attachment" : "link_variant"
							]
						}}
					</v-icon>
					<span class="text-decoration-underline subtitle-1">
						{{
							source.attachment
								? $t("app.attachment_added")
								: $t("app.website_added")
						}}
					</span>
				</a>
			</div>
		</div>

		<!-- Addons icon, when click on it, Addons dialog will be open -->
		<v-tooltip v-if="showAdd" bottom color="wr_indigo_2">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					icon
					color="wr_blue_1"
					@click="dialog = true"
				>
					<img :src="require('@/assets/images/upload_2.png')" width="26" />
				</v-btn>
			</template>
			<span>{{ $t("app.upload_or_attach") }}</span>
		</v-tooltip>

		<AddonsDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			:source="source"
			@close="dialog = false"
			@attach-link="addLink"
			@on-upload-success="onAfterUploadSuccess"
			@remove-attachment="removeAddon('attachment')"
		></AddonsDialog>
	</div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AddonsDialog from "@/components/research/modules/gatherSources/source/AddonsDialog.vue";

export default {
	name: "SourceAddons",

	props: {
		sourceId: {
			required: true,
			type: Number,
		},
		showAdd: {
			required: true,
			type: Boolean,
		},
		showAddons: {
			required: true,
			type: Boolean,
		},
	},

	data() {
		return {
			dialog: false,
			transition: "slide-y-reverse-transition",
		};
	},

	components: {
		AddonsDialog,
	},

	computed: {
		...mapGetters({
			getSource: "source/getSource",
		}),

		isWorkRoute() {
			return this.$route.name === "SourcesWork";
		},

		source() {
			return this.getSource(this.sourceId);
		},
	},

	methods: {
		addLink(link, cb) {
			// To make relative url --> absolute
			var RgExp = new RegExp("^(?:[a-z]+:)?//", "i");
			var fullUrl = link;

			if (!RgExp.test(link)) {
				fullUrl = "http://" + link;
			}
			this.$emit("addLink", {
				url: this.$xss.sanitize(fullUrl),
				source: this.source,
			});

			cb();

			this.dialog = false;
		},

		async onAfterUploadSuccess() {
			try {
				this.$loader.start();

				await this.$store.dispatch("source/list");

				this.dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		getAttachmentUrl() {
			return `${this.$environments.base_api_url}${this.source.attachment}`;
		},

		async removeAddon(addon) {
			try {
				var source = Object.assign({}, this.source);
				source[addon] = null;

				this.$loader.start();

				await this.$store.dispatch("source/update", source);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
