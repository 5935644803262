<template>
	<v-dialog :value="dialog" max-width="600" persistent>
		<AppDialog
			:heading="{
				label: 'app.related_keywords',
			}"
			:action="{
				label: 'app.save',
				event: 'close',
			}"
			@close="$emit('close')"
		>
			<template v-slot:dialog-content>
				<div>
					{{ $t("app.select_keywords_from_list") }}
				</div>

				<v-chip-group column class="mt-4">
					<v-chip
						color="greyLighten2"
						v-for="keyword in fav_keyword_list"
						:key="keyword.id"
						@click="$emit('update-source', keyword)"
					>
						<v-icon
							small
							left
							v-if="isArrayHavingItem(selectedKeywords, keyword.id)"
							>$vuetify.icons.values.check_circle</v-icon
						>
						{{ keyword.name }}
					</v-chip>
				</v-chip-group>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import { mapState } from "vuex";
import AppDialog from "@/components/ui/AppDialog.vue";
import { isArrayHavingItem } from "@/utils/helpers";

export default {
	name: "KeywordsRelatedDialog",

	props: {
		dialog: {
			required: true,
		},
		related_keywords: {
			required: true,
		},
	},

	data() {
		return {
			isArrayHavingItem,
		};
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapState({
			fav_keyword_list: (state) => state.keyword.fav_list,
		}),

		selectedKeywords() {
			return this.related_keywords.map((a) => a.id);
		},
	},
};
</script>
