import { render, staticRenderFns } from "./TagDialog.vue?vue&type=template&id=14869251&scoped=true&"
import script from "./TagDialog.vue?vue&type=script&lang=js&"
export * from "./TagDialog.vue?vue&type=script&lang=js&"
import style0 from "./TagDialog.vue?vue&type=style&index=0&id=14869251&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14869251",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VChip,VChipGroup,VCol,VColorPicker,VDialog,VForm,VIcon,VRow,VTab,VTabItem,VTabs,VTabsItems,VTextField})
